.sidebar {
  max-width: 420px;
  width: 420px;
  margin-bottom: 40px;

  &__item-block {
    display: none;
  }

  &-head {
    display: flex;
    justify-content: space-between;
    background: $orange;
    align-items: center;
    padding-right: 21px;
    border-radius: 8px 8px 0px 0px;
  }

  &-head__wrap {
    display: none;
  }

  &__item {
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;

    a {
      padding: 20px;
      display: block;
      color: $black;
    }

    a.active+.sidebar__answer-list {
      display: block;
    }

    a.active+.sidebar__answer-list {
      a {
        padding: 5px;
      }
    }
  }

  &__item:last-child {
    border-radius: 0 0 8px 8px;
  }


  &__title {
    padding: 20px;
    font-size: 24px;
    font-weight: 600;
    color: $white;
    border-radius: 8px 8px 0px 0px;
    background: $orange;
    cursor: pointer;
    max-width: 408px;
  }


  &__subtitle {
    padding: 15px 15px;
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: $black;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  &__answer-list p {
    margin-bottom: 10px;
  }

  &__answer-list {
    display: none;
    padding: 20px 20px;

    a {
      font-size: 16px;
      font-weight: 500;
      color: $black;

      &:hover {
        color: $orange;
      }
    }
  }
}


.open-sidebar {
  display: none;
}

.open-sidebar.open {
  display: block;
}

.close-sidebar {
  display: block;
}

.close-sidebar.open {
  display: none;
}