* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 400;
  color: $black;
  letter-spacing: 0.02em;
  margin: 0;
  line-height: 21px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

textarea {
  resize: none;
}

h1 {
  font-size: 54px;
  line-height: 64px;
}

h2 {
  font-size: 34px;
  line-height: 41px;
}

h3 {
  font-size: 24px;
}

p,
ul,
li {
  display: block;
  padding: 0;
  margin: 0;
}

a,
a:hover,
a:active {
  text-decoration: none;
}

a {
  transition: all 0.5s ease;
}


input:focus,
button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

button {
  cursor: pointer;
  transition: all .3s ease;
  font-size: 18px;
  border: 1px solid transparent;
}

p {
  line-height: 1.5em;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 990px
  }
}


@media (min-width: 1200px) {
  .container {
    max-width: 1702px
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.gamburger {
  display: none;
}

.close {
  display: none;
}

.form-check-input {
  appearance: none;
  position: absolute;
}

.form-check-input:checked+.check__box {
  background-image: url(../images/checkbox-checked.svg);
}

.check__box {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  left: 0.5em;
  top: 0.3em;
  border-radius: 0.05em;
  background-image: url(../images/checkbox-unchecked.svg);
}

.modal-form {
  .check__box {
    left: -1.5em;
  }
}


#loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 240, 0.91);
  overflow: hidden;
  display: none;
}

label.error {
  font-size: 12px;
  color: red;
  position: absolute;
}

#name-error {
  top: 0;
}

#tel-error {
  top: 0;
}

#email-error {
  top: 0;
}

#checkbox-error {
  top: -13px;
}

input.error {
  border: 1px solid red !important;
}

input.valid {
  // border: 1px solid green !important;
}

.checkbox-error {
  bottom: -24px;
}

#loader img {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
}

#overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(19, 19, 19, 0.9);
  left: 0px;
  top: 0px;
  cursor: pointer;
  z-index: 9;
}

#thx {
  display: none;
  background: $white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  color: $orange;
  border-radius: 20px;
  z-index: 999;

  .close-button {
    position: absolute;
    right: -35px;
    top: 0;
  }
}

.button {
  width: 260px;
  height: 65px;
  background: $orange;
  border-radius: 8px;
  line-height: 65px;
  color: $white;
  font-weight: 600;

  &:hover {
    background: $white;
    color: $orange;
    box-shadow: 0 0 12px 1px $orange;
    border: 1px solid $orange;
  }
}

//breadcrumbs 
.breadcrumb {
  padding: 30px 0 50px;

  &-item {
    font-size: 15px;
    color: $black;
  }
}

//pagination

.pagination {
  display: flex;
  margin-top: 30px;
}

.page-item {
  border: 1px solid #D9D9D9;
  background-color: transparent;
  border-radius: 5px;
  width: 59px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid $orange;
    background-color: $orange;

    a {
      color: $white;
    }
  }

  a {
    color: $black;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.page-item.active {
  border: 1px solid $orange;
  background-color: $orange;
  border-radius: 5px;

  a {
    color: $white;
  }

}

.page-link {
  font-size: 20px;
}

.page-inner {
  display: grid;
  grid-template-columns: 420px 1fr;
  column-gap: 30px;
}

.padRight {
  padding: auto;
}

.desktop {
  display: none;
}