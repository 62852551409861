//mixins
@mixin between {
  display: flex;
  justify-content: space-between;
}

@mixin between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin around {
  display: flex;
  justify-content: space-around;
}

@mixin around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

//fonts mixin
@mixin font-face($font-family, $url, $weight) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('../fonts/#{$url}.woff2') format('woff2'),
    url('../fonts/#{$url}.woff') format('woff');
    font-weight: #{$weight};
    font-display: swap;
    font-style: normal;
  }
}