.product {
  padding-bottom: 150px;

  &-wrap {
    display: grid;
    grid-template-columns: 407px 1fr;
  }

  &__img {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-right: 30px;
    max-height: 100%;
    max-width: 100%;
    justify-content: center;
  }

  &__info {

    h3 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
      line-height: 20px;
    }


    &-btns button,
    a {
      width: 317px;
      height: 65px;
      text-align: center;
      border-radius: 8px;
      font-weight: 600;


    }

    &-btns {
      display: flex;
    }
  }

  &__btn-list {
    border: 1px solid transparent;
    color: $white;
    background: $orange;
    line-height: 65px;

    &:hover {
      background: $white;
      color: $orange;
      box-shadow: 0 0 12px 1px $orange;
      border: 1px solid $orange;
    }
  }

  &__btn-consult {
    background-color: transparent;
    color: $black;
    border: 1px solid $orange;
    margin-left: 20px;

    &:hover {
      background: $white;
      color: $orange;
      box-shadow: 0 0 12px 1px $orange;
      border: 1px solid $orange;
    }
  }

  &-desc {
    margin-top: 41px;

    h4 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
      line-height: 20px;
    }
  }

  .about__btn {
    font-size: 24px;


  }

  &-desc__title {
    font-weight: 600;
    margin-top: 30px;
  }

  &-desc__text {
    margin-top: 20px;
  }

  &-desc__list {
    margin-top: 20px;
    padding-left: 20px;

    li {
      display: list-item;
      margin-top: 15px;

      &::marker {
        color: $orange;
      }
    }
  }
}