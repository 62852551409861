.contacts {
  padding-bottom: 150px;

  &-inner {
    display: flex;
  }

  h3 {
    font-size: 24px;
    margin-top: 22px;
  }

  &-info {
    display: grid;
    grid-template-columns: 33% 36% 30%;
    margin-top: 40px;

    &__item {
      display: flex;
      align-items: flex-start;

      h4 {
        font-weight: 600;
      }

      img {
        margin-right: 15px;
      }
    }

    &__item:not(:last-child) {
      margin-right: 50px;
    }
  }

  &__phone {
    font-size: 16px;
    font-weight: 600;
    color: $black;

    &:hover {
      color: $orange;
    }
  }

  &__mail {
    color: $black;
    font-size: 16px;

    &:hover {
      color: $orange;
    }
  }

  &__subtitle {
    font-weight: 600;
    margin: 56px 0 24px;
  }

  &-map {
    height: 498px;

    iframe {
      width: 990px;
      height: 498px;
    }
  }
}